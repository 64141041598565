import { getApp, getConfig } from '@sydle/ui-utils';
import type { UIApiConfig, UserAPI } from '.';
import { defineAPIs, getAPI } from '.';
import { CURRENT_LANGUAGE } from './lib/constants';

defineAPIs();

const localLanguage = sessionStorage.getItem(CURRENT_LANGUAGE) ?? localStorage.getItem(CURRENT_LANGUAGE);
const defaultLanguage = getConfig<UIApiConfig>('ui-api')?.DEFAULT_LANGUAGE;
const userAPI = getAPI<UserAPI>('userAPI');

if (!localLanguage && defaultLanguage) {
  userAPI?.setCurrentLanguage(defaultLanguage);
}

// It's possible that the defined language in local storage is no longer an option.
const languageOptions = getApp()?.languageOptions;
const isLocalLanguageValid = languageOptions?.some((language) => language.code === userAPI?.getCurrentLanguage());
if (languageOptions && languageOptions.length > 0 && !isLocalLanguageValid) {
  userAPI?.setCurrentLanguage(languageOptions[0].code);
}
